import { BROWSER } from 'esm-env'

import { toast } from './Toast.svelte';
export { toast }
import FrameStack from './FrameStack.svelte';

let stateAtHandoff;
let currentCounter = BROWSER && window.history.state?.counter || -1
let openModals = [];

import Modal from "~/ui/Modal.svelte";

import { mount, unmount } from "svelte";

if (BROWSER) {
  document.getElementById("app").addEventListener('click', (e) => {
    const el = e.target.closest("[data-modal]")
    if (!el) return;

    e.preventDefault();
    e.stopPropagation();

    const disEl = e.target.closest(".disabled")
    if (disEl) return;

    const url = el.attributes.href.value;
    const component = el.dataset.modal;
    const nav = typeof el.dataset.nav == 'undefined' ? true : el.dataset.nav;

    showModal(FrameStack, {
      url,
      nav,
      component
    })
  })

  let initiator = null
  document.addEventListener('click', function(ev) {
    initiator = ev.target.closest('[data-loader]')    
  })
  
  document.addEventListener('inertia:start', function() {
    if (!initiator) return;
    initiator.classList.toggle('loading', true)
    initiator.disabled = true
    initiator.appendChild(document.createElement('div')).classList.add('spinner');
  })
  
  document.addEventListener('inertia:finish', function() {
    initiator = null
    document.querySelectorAll('[data-loader].loading').forEach(el => {
      el.classList.remove('loading');
      el.disabled = false
      el.querySelectorAll('.spinner').forEach(spinner => spinner.remove());
    });
  })

  window.addEventListener("popstate", function (e) {
    if (
      !e.state?.counter ||
      e.state.counter < currentCounter ||
      typeof history.state.counter == "undefined"
    ) {
      // navigated back
      onBack();
    } else if (e.state.counter >= currentCounter) {
      // navigated forward
      onForward();
    }
    currentCounter = e.state?.counter || 0;
  });
}

export function incrementCounter() {
  const version = JSON.parse(document.getElementById('app').dataset.page).version
  currentCounter++;
  history.pushState(
    {
      version: version,
      counter: currentCounter,
    },
    null,
  );
}

export function showModal(component, args) {
  return new Promise(async (resolve, reject) => {

    if (openModals.length == 0) stateAtHandoff = window.history.state

    const modal = mount(Modal, {
      target: document.body,
      props: {
        component,
        args,
      },
    });
    openModals.push(modal);
    incrementCounter();
  });
}

export function closeModal() {
  const modal = openModals.pop();
  if (modal) {
    modal.close().then(() => {
      unmount(modal);
    });
  }
  if (openModals.length === 0) {
    history.replaceState(stateAtHandoff, null);
  }
}

function onBack() {
  // console.log("back");
  if (openModals.length === 0) return;
  openModals[openModals.length - 1].back();
}

function onForward() {
  // console.log("forward");
}

export function showFlashes(flashes) {
  if (flashes.success) {
    toast.success({
      message: flashes.success,
      color: '#070'
    })
    delete flashes.success
  }
  if (flashes.info) {
    toast.info({
      message: flashes.info,
      color: '#444'
    })
    delete flashes.info
  }
  if (flashes.error) {
    toast.error({
      message: flashes.error,
      color: '#700'
    })
    delete flashes.error
  }
}

