import "@unocss/reset/tailwind.css";

import "~/global.css";

import { createInertiaApp } from "inertiax-svelte";
import { hydrate } from "svelte";
import resolve from "../resolve";

createInertiaApp({
  resolve,
  setup({ el, App, props }) {
    hydrate(App, { target: el, props });
  },
});